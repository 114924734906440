import Vue from 'vue'
import './plugins/axios'
import router from './router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import { library} from '@fortawesome/fontawesome-svg-core'
Vue.config.productionTip = false
import { 
  faEyeSlash,faEnvelopeOpen,faUserCircle, faKey, faLock,faServer, faImage,faSpinner,faBell,faInfoCircle,faLockOpen,faStar,faDashboard,
  faClose, faCheck,faUserGroup, faUserPlus, faPause, faHotel,faAngleLeft,faAngleRight,faAd,faBook,faPlus,faEdit,
  faPlay,faTasks ,faSearch,faBuilding,faInstitution,faArrowCircleRight,faHome,faGear,faCreditCard,faEllipsisV,
  faTrashCan,faBars,faAngleDoubleRight,faChevronCircleLeft,faPhone,faRefresh,faBank,faTruck,faAddressCard
} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faEyeSlash,faUserCircle,faKey, faLock, faClose,faImage, faServer ,faSpinner, faBell, faArrowCircleRight,faLockOpen,faStar,faEllipsisV,
  faCheck, faUserGroup, faUserPlus, faPause, faPlay, faHotel, faAngleLeft,faEdit,
  faAngleRight, faGear, faAd,faDashboard,faAngleDoubleRight,faChevronCircleLeft,faTruck,
  faEnvelopeOpen, faTasks, faSearch, faBuilding, faInstitution,faPhone,faRefresh,
  faInfoCircle, faHome, faCreditCard, faBook, faPlus, faTrashCan,faBars,faBank,faAddressCard)

Vue.component('faIcon',FontAwesomeIcon)
//Vue.component('fai',FontAwesomeIcon)
Vue.config.productionTip = false
//Vue.use(VueAxios , axios)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
