import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [

  {
    path:'/',
    name:"Login",
    meta:{
      title:'Admin Login'
    },
   component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },

  {
    path: '/neworders',
    name: 'NewOrders',
    meta:{
      title:'dashboard'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NewOrdersView.vue'),
    beforeEnter:(to,from, next)=>{
      store.dispatch('authenticate');
      if(store.getters.get_token===undefined){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

   {
    path: '/sizecharge',
    name: 'SizeCharge',
    meta:{
      title:'dashboard'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SizeCharge.vue'),
    beforeEnter:(to,from, next)=>{
      store.dispatch('authenticate');
      if(store.getters.get_token===undefined){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

  {
    path: '/feesetting',
    name: 'FeeView',
    meta:{
      title:'dashboard'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/FeeView.vue'),
    beforeEnter:(to,from, next)=>{
      store.dispatch('authenticate');
      if(store.getters.get_token===undefined){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },

 {
    path: '/processed',
    name: 'ProcessedOrders',
    meta:{
      title:'dashboard'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProcessedOrders.vue'),
    beforeEnter:(to,from, next)=>{
      store.dispatch('authenticate');
      if(store.getters.get_token===undefined){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    meta:{
      title:'setting'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingView.vue'),
    beforeEnter:(to,from, next)=>{
      store.dispatch('authenticate');
      if(store.getters.get_token===undefined){
        next({path:"/"})
      }
      else{
        next()
      }
    }
  }

]

const router = new VueRouter({
  mode:'history',
  base:process.env.BASE_URL,
  routes
})

export default router
