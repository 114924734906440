import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
        themes:{
        light:{
        primary:'#fe7b00',
        secondary:'#455a6a',
        blue1:'#0d47a1',
        processed:'#aeea00',
        processing:'#f9fbe7'
        }
        }
    },
});
