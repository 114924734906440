<template>
  <v-app class="bg">
    <v-app-bar
      app
      color="primary"
      dark
      v-if="$router.currentRoute.path!=='/'"
    >
      <div class="d-flex align-center">
       <v-avatar size="50">
       <v-img :src="$store.getters.get_api+'fetchCompanyPic/'+$store.getters.get_company_logo"></v-img>
      </v-avatar>
      </div>
      <div class="mx-1">{{$store.getters.get_company_name}}</div>
      <v-spacer></v-spacer>
      <div><v-text-field class="mt-6" outlined dense placeholder="search order">
        <template v-slot:append><faIcon class="mt-1" icon="search"/></template>
      </v-text-field></div>

      <v-btn
      icon
      fab
      @click.stop="$router.push('/setting')"
      >
      <faIcon icon="gear"/>
      </v-btn>

      <v-badge color="blue1" content="6" offset-x="30" offset-y="18">
      <v-btn
      icon
      fab
      >
      <faIcon icon="bell"/>
      </v-btn>
      </v-badge>

      <v-btn
      icon
      fab
      @click.stop="logout"
      >
      <faIcon icon="lock"/>
      </v-btn>
    </v-app-bar>

    <v-main class="body">
     <router-view/>
    </v-main>

    <v-menu
      top
      :close-on-click="closeOnClick"
      >
      <template v-slot:activator="{on,attrs}">
<v-btn v-on="on" v-show="$router.currentRoute.path!=='/'" v-bind="attrs" fab fixed bottom right color="primary" dark><faIcon icon="bars"/></v-btn>
      </template>
      <v-list>

        <v-list-item class="btn" @click.stop="move('neworders')">
          <v-list-item>
            <v-list-item-title>New Orders</v-list-item-title>
          </v-list-item>
        </v-list-item>

         <v-list-item class="btn" @click.stop="move('sizecharge')">
          <v-list-item>
            <v-list-item-title>Size Charges</v-list-item-title>
          </v-list-item>
        </v-list-item>

         <v-list-item class="btn" @click.stop="$router.push('feesetting')">
          <v-list-item>
            <v-list-item-title>Fee Setting</v-list-item-title>
          </v-list-item>
        </v-list-item>

         <v-list-item class="btn" @click.stop="$router.push('processed')">
          <v-list-item>
            <v-list-item-title>Processed</v-list-item-title>
          </v-list-item>
        </v-list-item>
      </v-list>
    </v-menu>

    <FeedbackModal 
    :show="this.$store.getters.get_show" 
    :progress="this.$store.getters.get_progress" 
    :success="this.$store.getters.get_success" 
    :error="this.$store.getters.get_error"
    />

  </v-app>
</template>

<script>
  import FeedbackModal from '@/components/FeedbackModal.vue'
  //import SettingView from './components/SettingView.vue'
export default {
  name: 'App',

  components: {
    //SettingView
    FeedbackModal
  },

  data: () => ({
   logo:require('./assets/logo.svg'),
   setting:true,
   closeOnClick:true
  }),
  methods:{
    move(route){
      if(this.$router.currentRoute.path!=='/'+route){
        this.$router.push('/'+route);
      }
    },
    logout(){
      this.$store.dispatch('openProgress');
      this.$axios({
        url:this.$store.getters.get_api+'logoutShipping',
        method:'get',
        headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${this.$store.getters.get_token}`
          }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.dispatch('closeProgress')
          this.$router.push('/')
        }
      }).catch((error)=>{
        this.$store.commit('set_progress',false)
        this.$store.commit('set_error',true)
        this.$store.commit('set_message','An error occured')
        return error;
      })
    }
  }
};
</script>

<style scoped>
  .btn:hover{
    background: #fe7b00;
    color:#fff;
  }
  
</style>
