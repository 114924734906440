import Vue from 'vue'
//import Vuex, { Store } from 'vuex'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //api: `http://${window.location.hostname}:3333/api/`,
    api:"https://proudlyanambra.com/api/",
    token:undefined,
    //company:{logo:'',name:'',email:'',mobile:''},
    company_name:undefined,
    company_email:undefined,
    company_id:undefined,
    company_mobile:undefined,
    company_logo:undefined,
    message:'An error occured when saving user',
    success:false,
    error:false,
    progress:false,
    show:false
    
  },
  getters: {
    get_api:(state)=>state.api,
    get_token:(state)=>state.token,
    get_company_name:(state)=>state.company_name,
    get_company_email:(state)=>state.company_email,
    get_company_id:(state)=>state.company_id,
    get_company_mobile:(state)=>state.company_mobile,
    get_company_logo:(state)=>state.company_logo,
    get_show:(state)=>state.show,
    get_message:(state)=>state.message,
    get_progress:(state)=>state.progress,
    get_success:(state)=>state.success,
    get_error:(state)=>state.error
  },
  mutations: {
    set_token(state,value){
      state.token = value;
    },
    set_company_id(state,value){
      state.company_id = value;
    },
    set_company_email(state,value){
      state.company_email = value;
    },
    set_company_mobile(state,value){
      state.company_mobile = value;
    },
    set_company_logo(state,value){
      state.company_logo = value;
    },
    set_company_name(state,value){
      state.company_name = value;
    },
    set_show(state,value){
      state.show = value
    },
    set_message(state,value){
      state.message = value
    },
    set_progress(state,value){
      state.progress = value
    },
    set_success(state,value){
      state.success = value
    },
    set_error(state,value){
      state.error = value
    }
  },
  actions: {
    showErrorMessage:async function(context,message){
      context.commit('set_error',true)
      context.commit('set_success',false)
      context.commit('set_progress',false)
      context.commit('set_message',message)
      context.commit('set_show',true)
    },
    showSuccessMessage:async function(context,message){
      context.commit('set_error',false)
      context.commit('set_success',true)
      context.commit('set_progress',false)
      context.commit('set_message',message)
      context.commit('set_show',true)
    },
    openProgress:async function(context){
      context.commit('set_error',false)
      context.commit('set_success',false)
      context.commit('set_progress',true)
      context.commit('set_message',undefined)
      context.commit('set_show',true)
    },
    closeProgress:async function(context){
       context.commit('set_error',false)
        context.commit('set_success',false)
        context.commit('set_progress',false)
        context.commit('set_message',undefined)
        context.commit('set_show',false)
    },
     authenticate:async function(context){
       if(window.localStorage.getItem('token')){
         context.commit('set_token',window.localStorage.getItem('token'));
         context.commit('set_company_name',window.localStorage.getItem('company_name'));
         context.commit('set_company_logo',window.localStorage.getItem('company_logo'));
         context.commit("set_company_email", window.localStorage.getItem('company_email'));
         context.commit("set_company_mobile", window.localStorage.getItem('company_mobile'));
       }
     }
  },
  modules: {
  }
})
